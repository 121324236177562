// Copyright 1999-2023. Plesk International GmbH. All rights reserved.

import apolloClient from '../apolloClient';

const serverSummaryReportLoader = async ({ request }) => {
    const url = new URL(request.url);
    const layoutId = url.searchParams.get('layoutId') || '';
    await apolloClient.query({
        query: require('queries/SummaryReportPage.graphql'),
        variables: { layoutId: layoutId ? parseInt(layoutId) : '' },
    });
};

const clientSummaryReportLoader = async ({ params: { clientId }, request }) => {
    const url = new URL(request.url);
    const layoutId = url.searchParams.get('layoutId') || '';
    await apolloClient.query({
        query: require('queries/ClientSummaryReport.graphql'),
        variables: { clientId: btoa(`Client:${clientId}`), layoutId: layoutId ? parseInt(layoutId) : '' },
    });
};

export const ServerSummaryReportRoute = {
    path: '/cp/server/summary-report',
    children: [{
        index: true,
        componentLoader: () => import(/* webpackChunkName: "components/pages/SummaryReportPage" */'components/pages/SummaryReportPage'),
        loader: serverSummaryReportLoader,
    }, {
        path: 'print',
        componentLoader: () => import(/* webpackChunkName: "components/pages/SummaryReportPage/SummaryReportPrintPage" */
            'components/pages/SummaryReportPage/SummaryReportPrintPage'),
        loader: serverSummaryReportLoader,
    }, {
        path: 'layouts',
        componentLoader: () => import(/* webpackChunkName: "components/pages/SummaryReportLayoutsPage" */'components/pages/SummaryReportLayoutsPage'),
        loader: async () => {
            const { data } = await apolloClient.query({
                query: require('queries/SummaryReportLayoutsPage.graphql'),
            });
            return data;
        },
        children: [{
            path: 'add',
            componentLoader: () => import(
                /* webpackChunkName: "components/pages/SummaryReportLayoutsPage" */
                'components/pages/SummaryReportLayoutsPage/AddPage'
            ),
            loader: async () => {
                const { data } = await apolloClient.query({
                    query: require('queries/SummaryReportLayoutAddPage.graphql'),
                });
                return data;
            },
        }, {
            path: ':layoutId/edit',
            componentLoader: () => import(
                /* webpackChunkName: "components/pages/SummaryReportLayoutsPage" */
                'components/pages/SummaryReportLayoutsPage/EditPage'
            ),
            loader: async ({ params: { layoutId } }) => {
                const { data } = await apolloClient.query({
                    query: require('queries/SummaryReportLayoutEditPage.graphql'),
                    variables: { id: btoa(`SummaryReportLayout:${layoutId}`) },
                });
                return data;
            },
        }],
    }, {
        path: 'traffic-history',
        componentLoader: () => import(/* webpackChunkName: "components/pages/TrafficHistoryReportPage" */'components/pages/TrafficHistoryReportPage'),
        loader: async () => {
            await apolloClient.query({
                query: require('queries/TrafficHistoryReportPage.graphql'),
            });
        },
    }],
};


export const ClientSummaryReportRoute = {
    path: '/cp/client/:clientId/summary-report',
    children: [{
        index: true,
        componentLoader: () => import(
            /* webpackChunkName: "components/pages/SummaryReportPage/ClientSummaryReportPage" */'components/pages/SummaryReportPage/ClientSummaryReportPage'),
        loader: clientSummaryReportLoader,
    }, {
        path: 'print',
        componentLoader: () => import(
            /* webpackChunkName: "components/pages/SummaryReportPage/ClientSummaryReportPrintPage" */'components/pages/SummaryReportPage/ClientSummaryReportPrintPage'),
        loader: clientSummaryReportLoader,
    }, {
        path: 'traffic-history',
        componentLoader: () => import(/* webpackChunkName: "components/pages/ClientTrafficHistoryReportPage" */'components/pages/ClientTrafficHistoryReportPage'),
        loader: async ({ params: { clientId } }) => {
            await apolloClient.query({
                query: require('queries/ClientTrafficHistoryReportPage.graphql'),
                variables: { clientId: btoa(`Client:${clientId}`) },
            });
        },
    }],
};
