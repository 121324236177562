// Copyright 1999-2023. Plesk International GmbH. All rights reserved.

import { readLocalStorageValue } from 'common/helpers/readLocalStorageValue';
import { PROTECTED_DIRECTORY_LIST_DEFAULT_INPUT } from 'components/pages/ProtectedDirectoryListPage/listDefaultInput';
import { protectedDirectoryAddRoute } from 'routes/protectedDirectoryAddRoute';
import { protectedDirectoryEditRoute } from 'routes/protectedDirectoryEditRoute';
import apolloClient from '../apolloClient';

import PROTECTED_DIRECTORY_LIST from 'queries/ProtectedDirectoryList.graphql';
import PROTECTED_DIRECTORY_LIST_PAGE from 'queries/ProtectedDirectoryListPage.graphql';

export const protectedDirectoryListRoute = {
    path: '/cp/domains/:domainId/protected-directories',
    componentLoader: () => import(
        /* webpackChunkName: "components/pages/ProtectedDirectoryListPage" */
        'components/pages/ProtectedDirectoryListPage'
    ),
    loader: async ({ params }) => {
        const input = readLocalStorageValue(
            `ProtectedDirectoryListPageInput${params.domainId}`,
            PROTECTED_DIRECTORY_LIST_DEFAULT_INPUT,
        );
        const id = btoa(`Domain:${params.domainId}`);

        const [{ data: domainData }, { data: listData }] = await Promise.all([
            apolloClient.query({
                query: PROTECTED_DIRECTORY_LIST_PAGE,
                variables: { id },
            }),
            apolloClient.query({
                query: PROTECTED_DIRECTORY_LIST,
                variables: { id, input },
            }),
        ]);

        if (!listData.domain.hosting) {
            throw new Response('Not Found', { status: 404 });
        }

        return domainData;
    },
    children: [
        protectedDirectoryAddRoute,
        protectedDirectoryEditRoute,
    ],
};
